import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";

const neumorphismCardStyle = {
  boxShadow: "8px 8px 16px #d1d1d1, -8px -8px 16px #ffffff",
  backgroundColor: "#f0f0f0",
  borderRadius: "10px",
};
const neumorphismButtonStyle = {
  boxShadow: "4px 4px 8px #bebebe, -4px -4px 8px #ffffff",
  borderRadius: "10px",
};
// Mock data for tires - replace with your actual data
const tires = [
  {
    id: "tire1",
    brand: "Bridgestone",
    model: "POTENZA S001 RFT",
    price: "309.99",
    rating: 4.3,
    image:
      "https://cdn.sullivantire.com/tires/bridgestone-summer-potenza-s001-runflat_456-1-l.png",
    isTrending: true,
  },
  {
    id: "tire2",
    brand: "Bridgestone",
    model: "POTENZA RE050A",
    price: "257.99",
    rating: 4.5,
    image:
      "https://cdn.sullivantire.com/tires/bridgestone-summer-potenza-re050a_618-1-l.png",
    isTrending: false,
  },
  {
    id: "tire3",
    brand: "Pirelli",
    model: "Scorpion All Terrain",
    price: "599.99",
    rating: 4.9,
    image:
      "https://simpletire.com/_next/image?url=https%3A%2F%2Fimages.simpletire.com%2Fimages%2Fq_auto%2Fline-images%2F15790%2F15790-sidetread%2Fpirelli-scorpion-all-terrain-plus.png&w=3840&q=75",
    isTrending: true,
  },
];

const TireCard = ({ tire }) => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // Function to get the cookie by name
  const getCookieByName = (name) => {
    const match = document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]+)")
    );
    if (match) return JSON.parse(decodeURIComponent(match[2]));
    return null;
  };
  useEffect(() => {
    const pandaCookie = getCookieByName("pandaCookie");
    const active = pandaCookie && pandaCookie.tire === tire.brand;
    setIsActive(active);
  }, [tire]);
  const renderTireRating = (score) => (
    <div className="flex items-center justify-center mt-2">
      <div className="bg-orange-200 text-orange-700 text-xs px-2 inline-block rounded-full">
        {score} ★
      </div>
    </div>
  );

  const handleAddToCartClick = () => {
    console.log(`Added ${tire.model} to cart`);
    // Here you would implement the functionality to add the tire to the cart
  };
  const calculateOfferPrice = (originalPrice) => {
    const discount = 0.2; // 20% discount
    const discountedPrice = originalPrice - originalPrice * discount;
    return discountedPrice.toFixed(2); // Rounds to two decimal places and converts to string
  };
  // Function to handle button click
  const handleButtonClick = async () => {
    if (isActive) {
      setShowModal(true); // Show the modal immediately when the button is clicked
      setIsLoading(true); // Start loading
      const pandaCookie = getCookieByName("pandaCookie");
      const email = pandaCookie.email;
      const brand = pandaCookie.brand;

      // Calculate the offer price with 20% discount
      const offerPrice = calculateOfferPrice(parseFloat(tire.price));

      const payload = {
        programId: 876,
        templateId: 666,
        templateTierId: 406,
        person: {
          email: email,
        },
        passdata: {
          metaData: {
            prize: `$${offerPrice}`,
            brand: brand,
            product: tire.model,
          },
        },
      };
      try {
        const REACT_APP_BACKEND_URL =
          process.env.REACT_APP_BACKEND_URL || "http://localhost:3001";
        await axios.post(`${REACT_APP_BACKEND_URL}/update-wallet`, payload);
        console.log(`card updated for promotional pricing`);
      } catch (error) {
        console.error("Failed to update order status for promotional pricing:", error);
      } finally {
        setIsLoading(false); // Stop loading regardless of the result
      }
    } else if (buttonText === "Join And Save") {
      // Logic for "Join And Save" button
      const pandaCookie = getCookieByName("pandaCookie");
      if (pandaCookie) {
        const { email, firstName, lastName } = pandaCookie;
        // Construct the URL with variables from pandaCookie for "Join And Save"
        const targetUrl = `https://wallet-pass-sandbox.bambumeta.software/link-activation/8fe139a7?email=${encodeURIComponent(email)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}`;
        // Redirect to the URL
        window.open(targetUrl, '_blank');
      }
    }
  };

  const buttonText = isActive ? "Promotional Pricing" : "Join And Save";
  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
      <div
        key={tire.id}
        className="flex flex-col items-center p-4"
        style={neumorphismCardStyle}
      >
        <div className="w-48 h-48 flex items-center justify-center">
          <img
            src={tire.image}
            alt={tire.model}
            className="max-w-full max-h-full"
          />
        </div>
        {showModal && (
          <div
            className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
            id="my-modal"
          >
            <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-2/3 lg:w-1/2 shadow-lg rounded-md bg-white">
              <div className="mt-3 text-center">
                <div className="flex justify-center">
                  <img
                    src="https://www.passcreator.com/_Resources/Persistent/98c1e18d9fe99190fd8df08fcc83e559fad59759/Feature-Backside-1250-468x562.gif"
                    alt="Promotional Pricing"
                    style={{ width: "200px" }} 
                  />
                </div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2 mb-4">
                  Promotional Pricing
                </h3>
                <div className="px-7 py-3">
                  <p className="text-sm text-gray-500">
                    Your promotional pricing will be available once you sign up
                    for our digital wallet card. Get exclusive deals and
                    discounts with our membership! To access your promotional
                    details, open your wallet app and click on the three dots on
                    your digital card to flip to the backside. Look for the
                    "Details" section for more information on how to redeem your
                    exclusive offers.
                  </p>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    id="ok-btn"
                    className="px-4 py-2 bg-simpletire-orange text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-simpletire-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-simpletire-orange"
                    onClick={() => setShowModal(false)} // Assuming you have a method called setShowModal to update the state
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {renderTireRating(tire.rating)}
        <div className="text-center mt-4">
          <h3 className="text-lg font-bold">{tire.brand}</h3>
          <p className="text-md">{tire.model}</p>
          <p className="text-gray-900 font-semibold">{`Start at $${tire.price}`}</p>
          <div className="flex flex-col space-y-2 mt-4">
            <button
              className={`py-2 px-4 font-semibold text-white transition-colors ${
                isActive
                  ? "bg-orange-500 hover:bg-orange-600"
                  : "bg-black text-white cursor-not-allowed"
              }`}
              style={neumorphismButtonStyle}
              onClick={handleButtonClick}
              
            >
              {isLoading ? (
                <FaSpinner className="animate-spin h-5 w-5" />
              ) : (
                buttonText
              )}
            </button>
            <button
              className="py-2 px-4 font-semibold text-white bg-blue-500 hover:bg-blue-600 transition-colors"
              style={neumorphismButtonStyle}
              onClick={handleAddToCartClick}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const TireListingPage = () => {
  return (
    <div style={{ backgroundColor: "#ECEBE1" }}>
      <div className="container mx-auto px-6 py-8">
        <h1 className="text-3xl font-semibold leading-tight">
          Our Selection of Tires
        </h1>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {tires.map((tire) => (
            <TireCard key={tire.id} tire={tire} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TireListingPage;
