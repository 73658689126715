import React, { useState } from "react";
import { GoogleLogin } from "@leecheuk/react-google-login";
import { FaFacebookF, FaTwitter, FaGoogle } from "react-icons/fa"; // Ensure you have 'react-icons' installed
// Add neumorphism styles for the form and buttons
const neuFormStyle = {
  boxShadow: "8px 8px 16px #d1d1d1, -8px -8px 16px #ffffff",
  backgroundColor: "#f0f0f0",
  borderRadius: "20px",
};

const neuButtonStyle = {
  boxShadow: "4px 4px 8px #bebebe, -4px -4px 8px #ffffff",
  borderRadius: "20px",
};

function WalletButton({ formIsValid }) {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  const isAndroid = /android/i.test(userAgent);

  let buttonImage = isIOS
    ? `${process.env.PUBLIC_URL}/apple_wallet_button.svg`
    : isAndroid
    ? `${process.env.PUBLIC_URL}/google_wallet_button.png`
    : null;
  let buttonAltText = isIOS ? "Apple Wallet Button" : "Google Wallet Button";

  // Conditionally set the opacity based on form validity
  //const buttonStyles = formIsValid
   // ? "w-full h-auto"
   // : "w-full h-auto opacity-50";
 // const disabledClass = !formIsValid ? "cursor-not-allowed" : "";
 // Conditionally set the styles based on form validity
 const buttonStyles = formIsValid ? "w-full h-auto" : "w-full h-auto opacity-50";
 const disabledClass = !formIsValid ? "cursor-not-allowed" : "";

 return (
   buttonImage ? (
     <div className={`w-full flex justify-center p-3 mt-6 ${disabledClass}`}>
       <button type="submit" className="w-3/4 sm:w-full" disabled={!formIsValid}>
         <img src={buttonImage} alt={buttonAltText} className={buttonStyles} />
       </button>
     </div>
   ) : (
     <button
       type="submit"
       className={`w-full bg-simpletire-orange text-white py-2 px-4 rounded-full shadow-lg mt-6 font-bold ${
         formIsValid ? "hover:bg-simpletire-orange-dark transition-all duration-300 ease-in-out" : "opacity-50 cursor-not-allowed"
       }`}
       style={neuButtonStyle}
       disabled={!formIsValid}
     >
       Sign Up
     </button>
   )
 );
}

function SignUpForm() {
  
  
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    favoriteTire: "",
  });
  const [isSignedIn, setIsSignedIn] = useState(false);
  // Define a list of cars
  const cars = [
    "Toyota Camry",
    "Honda Accord",
    "Ford F-150",
    "Chevrolet Silverado",
    "Tesla Model S",
    "Nissan Altima",
    "BMW 3 Series",
    "Mercedes-Benz C-Class",
    "Audi A4",
    "Volkswagen Jetta",
  ];
  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };
  // Function to generate a random car from the list
  function getRandomCar() {
    const randomIndex = Math.floor(Math.random() * cars.length);
    return cars[randomIndex];
  }
  const responseGoogle = (response) => {
    if ("profileObj" in response) {
      const { givenName, familyName, email } = response.profileObj;
      setFormData({
        ...formData,
        firstName: givenName,
        lastName: familyName,
        email: email,
      });
      setIsSignedIn(true);
    } else {
      console.error("Google Sign-In error:", response);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
// Calculate formIsValid right after state definition
const formIsValid = formData.firstName && formData.lastName && formData.email && formData.favoriteTire;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cookieValue = JSON.stringify({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      orderStatus: "none",
      tire: formData.favoriteTire,
    });

    setCookie("pandaCookie", cookieValue, 7);
    const randomCar = getRandomCar();
    const queryParams = new URLSearchParams({
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      myvehicle: randomCar,
      points: "100",
      favoriteTire: formData.favoriteTire,
    });

    let url;
    if (formData.favoriteTire === "Bridgestone") {
      url = `https://wallet-pass-sandbox.bambumeta.software/brand-activation/80c8c878?${queryParams.toString()}`;
    } else if (formData.favoriteTire === "Pirelli") {
      url = `https://wallet-pass-sandbox.bambumeta.software/brand-activation/310b4135?${queryParams.toString()}`;
    } else {
      url = `https://some-default-url?${queryParams.toString()}`;
    }

    window.open(url, "_blank");
  };

  return (
    <div
      className="min-h-screen flex justify-center items-center"
      style={{ backgroundColor: "#ECEBE1" }}
    >
      <form
        className="py-8 px-10 rounded-lg w-full max-w-md"
        style={neuFormStyle}
        onSubmit={handleSubmit}
      >
        <div className="mb-6 text-center">
          <h2 className="text-3xl font-bold text-gray-900 my-6 text-center">
            Get your digital card
          </h2>
        </div>
        {/* Social Login Buttons */}
        <div className="flex justify-around mb-4">
          <button
            type="button"
            className="text-blue-600 text-xl rounded-full border border-blue-600 p-2 hover:bg-blue-600 hover:text-white"
            style={neuButtonStyle}
          >
            <FaFacebookF />
          </button>
          <button
            type="button"
            className="text-blue-400 text-xl rounded-full border border-blue-400 p-2 hover:bg-blue-400 hover:text-white"
            style={neuButtonStyle}
          >
            <FaTwitter />
          </button>
          <GoogleLogin
            clientId="886122045832-2j1aes3naf824rs6a4o2gg78r5hne2r8.apps.googleusercontent.com" // Replace with your Google client ID
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="text-red-600 text-xl rounded-full border border-red-600 p-2 hover:bg-red-600 hover:text-white"
                style={neuButtonStyle}
              >
                <FaGoogle />
              </button>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>

        {/* Divider */}
        <div className="my-4 flex items-center justify-between">
          <span className="border-b w-1/5 lg:w-1/4"></span>
          <p className="text-xs text-center text-gray-500 uppercase">
            or sign up with email
          </p>
          <span className="border-b w-1/5 lg:w-1/4"></span>
        </div>

        {/* Sign Up Form */}
        <div className="space-y-4">
          <input
            name="firstName"
            className="w-full p-2 border border-gray-300 rounded"
            type="text"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
          />
          <input
            name="lastName"
            className="w-full p-2 border border-gray-300 rounded"
            type="text"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
          />
          <input
            name="email"
            className="w-full p-2 border border-gray-300 rounded"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <select
            name="favoriteTire"
            className="w-full p-2 border border-gray-300 rounded-md text-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={formData.favoriteTire}
            onChange={handleChange}
          >
            <option value="">Select your favorite tire</option>
            <option value="Bridgestone">Bridgestone</option>
            <option value="Pirelli">Pirelli</option>
          </select>
        </div>
        <WalletButton formIsValid={formIsValid} />
      </form>
    </div>
  );
}
export default SignUpForm;
