import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import SignUpForm from './components/Login'; // Adjust if necessary
import OrderConfirmation from './components/OrderConfirmation'; // Adjust path as necessary
import NavigationBar from './components/NavBar';
import ProductListing from './components/ProductListing';

function App() {
  return (
    <Router>
      <div className="App">
        <NavigationBar />
        <Routes>
          {/* Define your routes here */}
          <Route path="/" element={<SignUpForm />} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />
          <Route path="/product-listing" element={<ProductListing/>} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
