import React, { useState } from 'react';
import { FaShoppingCart, FaUserAlt, FaMapMarkerAlt, FaSearch, FaBars, FaTimes } from 'react-icons/fa';
const neuNavBarStyle = {
  backgroundColor: '#e0e0e0',
  boxShadow: '8px 8px 16px #a3b1c6, -8px -8px 16px #ffffff',
  borderRadius: '10px',
};
function NavigationBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
    const handleShoppingCartClick = async (event) => {
        event.preventDefault(); 
        // Get pandaCookie
        const pandaCookie = document.cookie.split('; ').find(row => row.startsWith('pandaCookie='));
        let email = '';
        if (pandaCookie) {
            const pandaData = JSON.parse(decodeURIComponent(pandaCookie.split('=')[1]));
            email = pandaData.email; // Assuming the pandaCookie stores an object with the email
        }
        // Prepare the JSON payload
        const payload = {
            "person": {
              "email": email            },
            "passdata": {
              "metaData": {
                "orderstatus": "Placed"
              }
            },
            "dynamicImageUrl": "https://storage.googleapis.com/bambu-demos.appspot.com/order-status-1.jpg",
            "currentMessage": "Your order has been placed!"
          };
      
          // Call the update-wallet backend function
          try {
            const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';
            const response = await fetch(`${API_BASE_URL}/update-wallet`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            });
      
            if (response.ok) {
              console.log('Wallet updated successfully');
              // Redirect to the order confirmation page after the wallet update is successful
              window.location.href = '/order-confirmation'; // Adjust if you have a different URL
            } else {
              console.error('Failed to update wallet');
            }
          } catch (error) {
            console.error('Error updating wallet:', error);
          }
        };
    
        return (
          <nav className="shadow bg-white relative" style={neuNavBarStyle}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16 items-center">
                <div className="flex justify-start w-full">
                  {/* Logo and Mobile Menu Icon */}
                  <div className="flex items-center mr-4">
                    <button className="text-gray-900 hover:text-gray-700 sm:hidden mr-4" onClick={toggleMenu}>
                      {isMenuOpen ? <FaTimes /> : <FaBars />}
                    </button>
                    <a href="/">
                    <img
                      src={`${process.env.PUBLIC_URL}/SimpleTireLogo.png`}
                      alt="Simple Tire Logo"
                      style={{ width: '160px' }} // You can replace '160px' with the width you desire
                    />
                  </a>

                  </div>
                  {/* Navigation Links */}
                  <div className={`sm:ml-6 ${isMenuOpen ? 'block' : 'hidden'} sm:flex items-center`}>
                    <a href="/product-listing" className="text-gray-900 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium">Browse Tires</a>
                    <a href="https://simpletire.com/tire-deals" className="text-gray-900 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium">Deals</a>
                    <a href="#" className="text-gray-900 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium">Learn</a>
                  </div>
                </div>
                {/* Right Section */}
                <div className="flex items-center space-x-2">
                  <a href="#" className="text-gray-900 hover:text-gray-700 rounded-md text-sm font-medium flex items-center">
                    <FaMapMarkerAlt className="mr-2 text-lg" />
                    Marietta
                  </a>
                  <a href="#" className="text-gray-900 hover:text-gray-700 rounded-md text-sm font-medium">
                    <FaUserAlt className="text-lg" />
                  </a>
                  <a href="/order-confirmation" onClick={handleShoppingCartClick} className="text-gray-900 hover:text-gray-700 rounded-md text-sm font-medium relative">
                    <FaShoppingCart className="text-lg" />
                    <span className="absolute -top-1 -right-2 rounded-full bg-red-600 w-4 h-4 flex items-center justify-center text-white font-mono text-xs">1</span>
                  </a>
                </div>
              </div>
            </div>
            {/* Mobile Menu */}
            <div className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right ${isMenuOpen ? 'block' : 'hidden'}`}>
              <div className="rounded-lg shadow-lg bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                    <a href="/">
                    <img
                      src={`${process.env.PUBLIC_URL}/SimpleTireLogo.png`}
                      alt="Simple Tire Logo"
                      style={{ width: '120px' }} // You can replace '160px' with the width you desire
                    />
                  </a>
                    </div>
                    <div className="-mr-2">
                      <button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={toggleMenu}>
                        <FaTimes className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid gap-y-8">
                      <a href="/product-listing" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                        {/* Icons and links for mobile menu */}
                        <span className="ml-3 text-base font-medium text-gray-900">Browse Tires</span>
                      </a>
                      <a href="/product-listing" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                        {/* Icons and links for mobile menu */}
                        <span className="ml-3 text-base font-medium text-gray-900">Deals</span>
                      </a>
                      <a href="/product-listing" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                        {/* Icons and links for mobile menu */}
                        <span className="ml-3 text-base font-medium text-gray-900">Learn</span>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        );
}
export default NavigationBar;
