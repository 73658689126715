import React from 'react';
import { FaCheckCircle, FaShippingFast, FaCalendarCheck } from 'react-icons/fa';
import OrderProgress from './OrderProgress'; 
  // Neumorphic style
  const neuStyle = {
    boxShadow: '8px 8px 15px #aaa, -8px -8px 15px #fff',
    backgroundColor: '#e0e0e0',
    paddingBottom: '20px',
  };
  function getFormattedDate() {
    const today = new Date();
    return today.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  }
function OrderConfirmation() {
    const getPandaCookie = () => {
        const pandaCookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith('pandaCookie='))
          ?.split('=')[1];
        return pandaCookieValue ? JSON.parse(decodeURIComponent(pandaCookieValue)) : null;
      };
    
      // Get the pandaCookie data
      const pandaData = getPandaCookie();
    
      // Use the first name and last name from the pandaCookie, or default to placeholders if not found
      const customerFirstName = pandaData?.firstName || 'First';
      const customerLastName = pandaData?.lastName || 'Last';
    
      // Construct the customerName using the retrieved first name and last name
      const customerName = `${customerFirstName} ${customerLastName}`;
    
      const orderDetails = {
        customerName: customerName,
        orderDate: getFormattedDate(),
        items: [
        {
        id: 1,
        title: 'Bridgestone Potenza RE40',
        discount: '-$50.00',
        priceBeforeDiscount: '$257.99',
        priceAfterDiscount: '$206.39',
        productId: 'ID123456',
        variant: 'RE40',
        vendor: 'Bridgestone',
        quantity: 1,
        image: 'https://cdn.discounttire.com/sys-master/images/h98/haa/9120040812574/PRODUCT_201909131044_tire_25422_1000_angle.png_dt-product-desktop', 
      }
      // ... other items
    ],
    subtotal: '$257.99',
    discount: '20%',
    shipping: 'FREE',
    total: '$206.39',
  };

  // You can make this dynamic based on the actual order status
  const orderStatus = {
    ordered: true,
    readyToShip: true,
    expectedDelivery: false
  };

  return (
    <div className="min-h-screen flex flex-col items-center px-4 pt-10 mb-10" style={neuStyle}>
      <div className="max-w-2xl w-full bg-white shadow-lg rounded-lg p-8 border border-gray-200" style={neuStyle}>
  
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold text-simpletire-black">ORDER CONFIRMATION</h1>
          <p className="text-md mt-2 text-simpletire-black">{orderDetails.customerName}, thank you for your order!</p>
          <p className="text-md text-simpletire-black">We've received your order and will contact you as soon as your package is shipped.</p>
        </div>
  
        <div className="my-4 border-t border-b border-gray-300 py-4">
          <h2 className="text-lg font-semibold text-center text-simpletire-black">Order Summary</h2>
          <p className="text-md text-center text-simpletire-black">{orderDetails.orderDate}</p>
          {/* Loop through items */}
          {orderDetails.items.map(item => (
            <div key={item.id} className="flex justify-between items-center mt-4">
              <img src={item.image} alt={item.title} className="w-24 h-24 object-cover rounded-md" />
              <div className="flex flex-col items-start justify-center text-md text-simpletire-black ml-4">
                <p className="font-bold">{item.title}</p>
                <p>Discount: {item.discount}</p>
                <p>Price: {item.priceAfterDiscount}</p>
                {/* ... other details */}
              </div>
            </div>
          ))}
        </div>
  
        {/* Horizontal Line */}
        <hr className="my-4 border-t border-gray-300" />
  
        <div className="mt-4">
          <div className="flex justify-between">
            <p className="text-sm text-simpletire-black">Subtotal price</p>
            <p className="text-sm text-simpletire-black">{orderDetails.subtotal}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-simpletire-black">Discount</p>
            <p className="text-sm text-simpletire-black">{orderDetails.discount}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-simpletire-black">Shipping price</p>
            <p className="text-sm text-simpletire-black">{orderDetails.shipping}</p>
          </div>
          <div className="flex justify-between font-bold">
            <p className="text-lg text-simpletire-black">Total price:</p>
            <p className="text-lg text-simpletire-orange mb-5">{orderDetails.total}</p>
          </div>
        </div>
  
        
        <OrderProgress orderStatus={orderStatus} />
  
      </div>
    </div>
  );  
}

export default OrderConfirmation;
