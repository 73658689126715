import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheckCircle, FaTruck, FaBoxOpen } from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';

const neuStyle = {
  boxShadow: '8px 8px 15px #aaa, -8px -8px 15px #fff',
  backgroundColor: '#e0e0e0', // Replace with the background color you want
  borderRadius: '10px', // Adjust as necessary
};

function OrderProgress() {
  const [orderStatus, setOrderStatus] = useState({
    placed: true,
    shipped: false,
    delivered: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const getEmailFromPandaCookie = () => {
    const pandaCookie = document.cookie.split('; ').find(row => row.startsWith('pandaCookie='));
    if (!pandaCookie) return null; // Return null if cookie not found
    const pandaData = JSON.parse(decodeURIComponent(pandaCookie.split('=')[1]));
    return pandaData.email; // Assuming pandaCookie has an email field
  };
  const updateOrderStatus = async () => {
    const email = getEmailFromPandaCookie(); // Get email from pandaCookie
    if (!email) {
      console.error('Email not found in pandaCookie');
      return;
    }
  
    // Determine the new status and corresponding image URL based on the current state
    let newStatus = '';
    let dynamicImageUrl = '';
    if (orderStatus.shipped && !orderStatus.delivered) {
      newStatus = 'Shipped';
      dynamicImageUrl = "https://storage.googleapis.com/bambu-demos.appspot.com/order-status-2.jpg";
    } else if (orderStatus.delivered) {
      newStatus = 'Delivered';
      dynamicImageUrl = "https://storage.googleapis.com/bambu-demos.appspot.com/order-status-3.jpg";
    }
  
    const payload = {
      "person": {
        "email": email,
      },
      "passdata": {
        "metaData": {
          "orderstatus": newStatus,
        }
      },
      "dynamicImageUrl": dynamicImageUrl,
      "currentMessage": newStatus === 'Shipped' ? "Your order has shipped!" : "Your order has been delivered!",
    };
  
    try {
        const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';
        await axios.post(`${REACT_APP_BACKEND_URL}/update-wallet`, payload);        
      console.log(`Order status updated to ${newStatus}`);
    } catch (error) {
      console.error('Failed to update order status:', error);
    }
  };
  

  useEffect(() => {
    if (orderStatus.shipped && !orderStatus.delivered) {
      updateOrderStatus("Shipped");
    } else if (orderStatus.delivered) {
      updateOrderStatus("Delivered");
    }
  }, [orderStatus]);
  const handleStatusChange = async () => {
    setIsLoading(true); // Start loading
    await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 seconds
    
    setOrderStatus(currentStatus => {
      let newState;
      if (currentStatus.placed && !currentStatus.shipped) {
        newState = { ...currentStatus, shipped: true };
      } else if (currentStatus.shipped && !currentStatus.delivered) {
        newState = { ...currentStatus, delivered: true };
      } else {
        newState = currentStatus; // No change if already delivered
      }
      return newState;
    });
    
    setIsLoading(false); // Stop loading
  };
  
  

  return (
    <div className="p-6 text-center rounded-lg shadow-md relative" style={neuStyle}>
    {isLoading && (
      <div className="absolute inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
        <ClipLoader color="#FFFFFF" loading={isLoading} size={50} />
      </div>
      )}
      <h2 className="text-xl font-bold text-gray-800 mb-4">Woohoo! Your order is confirmed.</h2>
      <p className="text-gray-600 mb-6">We'll start working on this right away. We'll email you as soon as it ships.</p>

      {/* Progress Tracker with Labels */}
      <div className="flex justify-around items-center mb-6">
        {/* Placed Icon & Label */}
        <div className="flex flex-col items-center">
          <FaCheckCircle className={`text-3xl mb-1 ${orderStatus.placed ? 'text-green-500' : 'text-gray-300'}`} />
          <span className="text-sm font-semibold text-gray-700">Placed</span>
        </div>

        {/* Shipped Icon & Label */}
        <div className="flex flex-col items-center">
          <FaTruck className={`text-3xl mb-1 ${orderStatus.shipped ? 'text-orange-500' : 'text-gray-300'}`} />
          <span className="text-sm font-semibold text-gray-700">Shipped</span>
        </div>

        {/* Delivered Icon & Label */}
        <div className="flex flex-col items-center">
          <FaBoxOpen className={`text-3xl mb-1 ${orderStatus.delivered ? 'text-blue-500' : 'text-gray-300'}`} />
          <span className="text-sm font-semibold text-gray-700">Delivered</span>
        </div>
      </div>

      {/* Change Status Button */}
      {!orderStatus.delivered && (
        <button
          className="bg-simpletire-orange text-white py-2 px-6 rounded-full shadow-lg hover:bg-black transition-colors"
          onClick={handleStatusChange}
        >
          Change Status
        </button>
      )}
    </div>
  );
}

export default OrderProgress;
